import React, { ChangeEvent, CSSProperties, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import screenImage from '../../assets/images/welcome-pattern.png';
import { appWidth } from './CSD/WelcomeCSD';
import { Heading } from '../components/Heading';
import { Router } from '../router/Router';
import { Input } from '../components/Input';
import { Separator } from '../components/Separator';
import { Button } from '../components/Button';
import { emailChecker } from '../utils/InputChecker';
import { CustomCheckBox } from '../components/CustomCheckBox';
import { DeviceInfo, Profile } from '../models/Profile';
import { Loading } from '../components/Loading';
import { API } from '../utils/API';
import { ToastBar } from '../components/ToastBar';
import { NotAuthProvider } from '../components/NotAuthProvider';

export const SignUp: FC = () => {
  const navigation = useNavigate();

  const styles = {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
    } as CSSProperties,
    bgImage: {
      height: appWidth ? '45%' : '60%',
      position: 'fixed',
      bottom: 5,
      right: 0,
    } as CSSProperties,
    mainContainer: {
      paddingBlock: 20,
      width: 400,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: appWidth ? 30 : 100,
      marginTop: appWidth ? 50 : 0,
    } as CSSProperties,
    form: {
      width: '100%',
      display: 'flex',
      flex: appWidth ? 'unset' : 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    } as CSSProperties,
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isToastbarVisible, setToastbarVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  const handleBackPressed = () => Router(navigation).goToWelcome();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [secondNameError, setSecondNameError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [secondPasswordError, setSecondPasswordError] = useState<boolean>(false);

  const [smartCard, setSmartCard] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [secondName, setSecondName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [secondPassword, setSecondPassword] = useState<string>('');
  const [marketing, setMarketing] = useState<boolean>(true);
  const [terms, setTerms] = useState<boolean>(false);

  const handleSmartCard = (event: ChangeEvent<HTMLInputElement>) => setSmartCard(event.target.value);
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    const isValid = emailChecker(event.target.value);
    if (!isValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };
  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    if (event.target.value.length > 2) {
      setFirstNameError(false);
      return;
    }
    setFirstNameError(true);
  };
  const handleSecondNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSecondName(event.target.value);
    if (event.target.value.length > 2) {
      setSecondNameError(false);
      return;
    }
    setSecondNameError(true);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);

    if (event.target.value.length < 8) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
    if (event.target.value !== secondPassword) {
      setSecondPasswordError(true);
    }
  };
  const handlesecondPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSecondPassword(event.target.value);
    if (event.target.value !== password) {
      setSecondPasswordError(true);
      return;
    }
    setSecondPasswordError(false);
  };

  const handleMarketingChange = (event: ChangeEvent<HTMLInputElement>) => setMarketing(event.target.checked);
  const handleTermsChange = (event: ChangeEvent<HTMLInputElement>) => setTerms(event.target.checked);

  const buttonDisabled = !email.length || !password.length || !firstName.length || !secondName.length || !secondPassword.length || !terms
    || emailError || firstNameError || secondNameError || passwordError || secondPasswordError;

  const handleSignUp = () => {
    const user: Profile = { 
      badges: {}, 
      checked_tutorial: false, 
      daily_counter: 0, 
      daily_counter_limit: false, 
      email, 
      first_name: firstName, 
      has_requests: false, 
      is_sherpa: false, 
      is_staff: false,
      is_csd_representative: false,
      last_name: secondName, 
      marketing, 
      no_sell: false, 
      push: false, 
      smart_card_nfc_id: smartCard,
      username: email, 
      verified: false, 
      verified_sent: false,
    };

    const deviceInfo: DeviceInfo = {
      os: '',
      version: '',
    };

    setLoading(true);
    API.Users.signUp(user, password, deviceInfo)
      .then(() => {
        setLoading(false);
        Router(navigation).goToEmailVerification(email);
      })
      .catch((error) => {
        setLoading(false);
        if (error.errorCode) {
          setToastMessage(error.message);
          setToastbarVisible(true);
          setTimeout(() => setToastbarVisible(false), 3000);
          return;
        }
        setToastMessage('Something went wrong, try again later.');
        setToastbarVisible(true);
        setTimeout(() => setToastbarVisible(false), 3000);
      });
  };

  return (
    <NotAuthProvider>
      {isLoading && <Loading isVisible={isLoading} />}
      {isToastbarVisible && <ToastBar isVisible={isToastbarVisible} message={toastMessage} />}
      <img style={styles.bgImage} src={screenImage} alt="background" />
      <div style={styles.root}>
        <div style={styles.mainContainer}>
          <Heading title="Sign up" onBack={handleBackPressed} />
          <div style={styles.form}>
            {appWidth ? <Separator size="L" /> : <></>}
            <Input placeholder="Physical Smart Card (optional)" onError={false} errorMessage="" onChange={handleSmartCard} />
            <Separator size="S" />
            <Input placeholder="Email Address" onError={emailError} errorMessage="Write a valid email." onChange={handleEmailChange} type="email" />
            <Separator size="S" />
            <Input placeholder="First name" onError={firstNameError} errorMessage="Write a valid first name." onChange={handleFirstNameChange} />
            <Separator size="S" />
            <Input placeholder="Second name" onError={secondNameError} errorMessage="Write a valid second name." onChange={handleSecondNameChange} />
            <Separator size="S" />
            <Input placeholder="Password" onError={passwordError} errorMessage="Write a valid password." onChange={handlePasswordChange} type="password" />
            <Separator size="S" />
            <Input placeholder="Password Confirmation" onError={secondPasswordError} errorMessage="Passwords are different." onChange={handlesecondPasswordChange} type="password" />
            <Separator size="S" />

            <CustomCheckBox title="Yes please. I'd like the occasional update from Olyns." checked={marketing} onChange={handleMarketingChange} />
            <Separator size="S" />
            <CustomCheckBox title="I accept the Terms of Use" checked={terms} onChange={handleTermsChange} />

            <Separator size="M" />
            <Button type={buttonDisabled ? 'DISABLED' : 'PRIMARY'} width="100%" title="Sign me up!" onClick={handleSignUp} disabled={buttonDisabled} />
          </div>
        </div>
      </div>
    </NotAuthProvider>
  );
};
